.header__wrapper {
    width: 100%;
    height: var(--headerHeight);
}

.header__container {
    display: flex;
    justify-content: space-between;

    width: auto;
    height: auto;

    padding: 16px 48px;

    position: relative;
}

.header__logo {
    height: var(--logoHeight);
    width: auto;
    cursor: pointer;
}

.header__navigation {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.header__navigation__mobile {
    position: absolute;
    top: var(--headerHeight);
    left: 0;
    width: 100%;
    min-height: 100%;
    height: 100vh;

    z-index: 10000;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-base-light);
}

.header__navigation__menu {
    position: absolute;
    top: var(--headerHeight);
    right: 8%;
    width: auto;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--color-base-light);

    z-index: 1000;
}

.header__navigation__menu--about {
    right: 200px;
}

.header__navigation__menu--store {
    right: 48px;
}

.header__navigation__item--submenu {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-sm);
    color : var(--color-white);
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    padding: 8px;
}
.header__navigation__item--submenu:hover {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-sm);
    color : #FFFFFF;
    cursor: pointer;
    width: 200px;
    display: flex;
    justify-content: flex-start;
    padding: 8px;
    background-color: var(--color-primary);
}

.header__navigation__item {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-sm);
    color : #FFFFFF;
    margin: 0 8px;
    padding: 0;
    cursor: pointer;
}
.header__navigation__item:hover {
    color: var(--color-primary);
}

.header__navigation__item--mobile {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-lg);
    color : #FFFFFF;
    margin: 24px 0;
    padding: 0;
    cursor: pointer;
}
.header__navigation__item--mobile:hover {
    color: var(--color-primary);
}

.header__menu__icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {

}

@media screen and (max-width: 768px) {
    .header__wrapper {
        width: 100%;
        height: var(--headerHeight);
    }
    .header__container {
        padding: 16px 32px;
        align-items: center;
    }
    .header__logo {
        height: var(--logoHeight);
    }
    .header__navigation__menu {
        display: none;
    }

    .header__navigation__menu--mobile {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: #3D3D3DFF;

        overflow: hidden;
    }

    .header__navigation__item--submenu--mobile {
        font-family: 'Abel', sans-serif;
        font-size : var(--fs-md);
        color : var(--color-white);
        cursor: pointer;
        width: 100%;
        padding: 8px;
        text-align: center;
    }

}
