@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;700&display=swap');

:root {
  --fs-h1: 3.5rem;
  --fs-lg: 2.5rem;
  --fs-md: 1.5rem;
  --fs-sm: 1rem;
  --fs-xs: 0.8rem;

  --color-base-light: #2a2a2a;
  --color-base: #1D1D1B;
  --color-white: #FFFFFF;
  --color-grey: #e7e7e7;
  --color-primary: #E84E1B;
  --color-primary-2: #c53c12;
  --color-primary-3: #a93411;
  --color-secondary: #A88B70;

/*  SIZES   */
  --maxWidthContainer: 1600px;
  --headerHeight:  104px; /* 72px .header__logo + 32px .header__container padding */
  --logoHeight: 72px;

/*  OTHERS  */
  --box-shadow: 0px 6px 16px 0px #0000000F;
}

* {
  margin: 0;
  padding: 0;
}


html, body {
  height: 100%;
  width: auto;
  overflow-x: hidden;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--color-base-light);
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
  :root {
    --fs-h1: 2.5rem;
    --fs-lg: 1.75rem;
    --fs-md: 1.25rem;
    --fs-sm: 1rem;
    --fs-xs: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --fs-h1: 2.5rem;
    --fs-lg: 1.75rem;
    --fs-md: 1.25rem;
    --fs-sm: 1rem;
    --fs-xs: 0.8rem;
  }
}
