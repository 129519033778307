.diagnosisInterview__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 80%;
    height: auto;
}

.diagnosisInterview__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : #E84E1B;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .diagnosisInterview__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.diagnosisInterview__text {
    font-family: 'Raleway', sans-serif;
    font-size : var(--fs-md);
    line-height : var(--fs-md);
    color : var(--color-white);
}

.diagnosisInterview__text span {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-md);
    line-height : var(--fs-md);
    color : var(--color-white);
    font-weight : 700;
}

.diagnosisInterview__image {
    height: 600px;
    width: auto;
}