.podcast__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : var(--color-white);
    text-align: left;

    margin-bottom: 32px;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .podcast__title {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.podcast__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.podcast__image {
    width: 30%;
    height: auto;
    margin: 24px 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px
}

.podcast__text {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    font-weight: 700;
    color: #FFF;
    text-align: center;
    max-width: 80%;
}

@media screen and (max-width: 768px) {
    .podcast__image {
        width: 60%;
    }
}