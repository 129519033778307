.storeCard__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    box-shadow: 8px 8px 24px 0 rgba(255, 255, 255, 0.2);
    background-color: var(--color-white);
    padding-bottom: 24px;
    border: none;
}

.storeCard__image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.storeCard__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 700;
    color : var(--color-base);
    margin-top: 16px;
}

.storeCard__price {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-lg);
    line-height : var(--fs-lg);
    color : var(--color-primary);
    margin-top: 8px;
    margin-bottom: 16px;
}