.educationAlternatives__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.educationAlternatives__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : #E84E1B;

    max-width: 40%;
    align-self: flex-start;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .educationAlternatives__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.educationAlternatives__items__container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 88px 0;
}

.educationAlternatives__questions__container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 16px 0;
}

.educationAlternatives__buttons__container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    gap: 16px;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .educationAlternatives__title {
        max-width: 80%;
    }
    .educationAlternatives__items__container {
        margin: 40px 0;
    }
    .educationAlternatives__questions__container {
        margin: 32px 0;
    }
}

@media screen and (max-width: 768px) {
    .educationAlternatives__title {
        max-width: 90%;
    }
    .educationAlternatives__items__container {
        flex-direction: column;
        margin: 40px 0;
    }
    .educationAlternatives__questions__container {
        margin: 0 0 32px;
        flex-direction: column;
    }

    .educationAlternatives__buttons__container {
        flex-direction: column;
    }
    .walkService__button__mobile {
        width: 80%;
    }
}
