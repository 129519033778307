.brochureDownload__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: auto;
}

.brochureDownload__image {
    width: 304px;
    height: 400px;

    object-fit: cover;
}

.brochureDownload__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 500;
    color : var(--color-primary);
    text-align: center;

    margin-top: 16px ;
    max-width: 80%;
    cursor: pointer;
}
.brochureDownload__title:hover {
    font-weight : 700;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .brochureDownload__container {
        margin: 0 8px;
    }
    .brochureDownload__image {
        width: 100%;
        height: auto;
        aspect-ratio: 2/3;
    }
}

@media screen and (max-width: 768px) {
    .brochureDownload__container {
        margin: 16px 0;
    }
    .brochureDownload__image {
        width: 100%;
        height: auto;
        aspect-ratio: 2/3;
    }
}
