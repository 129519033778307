.landingAbout__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    margin: 120px 0;
}

.landingAbout__image {
    height: 224px;
    width: auto;
}

.landingAbout__image__invitation {
    height: 316px;
    width: auto;
    align-self: center;
    margin: 0 16px;
}
.landingAbout__image__invitation--mobile {
    display: none;
}

.landingAbout__text {
    font-family: 'Raleway', sans-serif;
    font-size: var( --fs-md);
    line-height: calc(var( --fs-md)*1.2);
    color : var(--color-white);

    margin-left: 24px;
}

.landingAbout__block {
    width: auto;
    height: 100%;

    min-width: 400px;
    max-width: 50%;

    display: flex;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landingAbout__container {
        margin: 104px 0;
    }
    .landingAbout__image {
        height: 176px;
    }
    .landingAbout__text {
        margin-left: 0;
    }
    .landingAbout__image__invitation {
        height: 248px;
        width: auto;
        margin: 0 24px;
    }
    .landingAbout__block {
        min-width: 364px;
        max-width: 60%;
    }
}

@media screen and (max-width: 768px) {
    .landingAbout__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }

    .landingAbout__image {
        height: 176px;
        margin: 24px 0;
    }

    .landingAbout__text {
        margin-left: 0;
        max-width: 80%;
        text-align: left;
    }

    .landingAbout__image__invitation {
        display: none;
    }

    .landingAbout__image__invitation--mobile {
        display: block;
        height: auto;
        width: auto;
        max-width: 80%;
        margin: 8px 0 16px;
    }
    .landingAbout__block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: auto;
        height: 100%;

        min-width: 100%;
        max-width: 100%;

        position: relative;
    }

}