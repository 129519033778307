.educationAlternativeItem__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-right: 60px;
}

.educationAlternativeItem__container:last-child {
    margin-right: 0;
}

.educationAlternativeItem__title__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.educationAlternativeItem__icon {
    width: 64px;
    height: auto;
    margin-right: 16px;
}

.educationAlternativeItem__title {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    font-weight : 700;
    color : #FFF;
}

.educationAlternativeItem__subtitle {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 500;
    color : #E84E1B;
}

.educationAlternativeItem__text {
    font-family: 'Raleway', sans-serif;
    font-size : var(--fs-md);
    line-height : var(--fs-md);
    color : var(--color-white);

    margin-top: 24px;
    max-width: 80%;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .educationAlternativeItem__container {
        margin-right: 32px;
    }
    .educationAlternativeItem__icon {
        width: 40px;
    }
    .educationAlternativeItem__text {
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .educationAlternativeItem__container {
        width: 100%;
        height: auto;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        margin-right: 0;
        margin-bottom: 40px;
    }
    .educationAlternativeItem__container:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }
    .educationAlternativeItem__icon {
        width: 32px;
    }
    .educationAlternativeItem__text {
        margin-top: 16px;
        max-width: 100%;
    }
}
