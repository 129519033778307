.educationDescription__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: auto;
    height: auto;

    padding: 64px 0;
}

.educationDescription__column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    width: 55%;
    height: auto;

    padding: 0 72px;
}

.educationDescription__image__container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 45%;
    height: auto;

    margin-left: 32px;
    overflow-x: hidden;
}

.educationDescription__title {
    font-family : 'Abel Regular', 'Abel', sans-serif;
    font-size : var(--fs-h1);
    line-height: calc(var( --fs-h1)*1.2);
    color : var(--color-primary);

    max-width: 600px;
}

.educationDescription__column__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: auto;

}

.educationDescription__column__text {
    font-family: 'Raleway', sans-serif;
    font-size: var( --fs-md);
    line-height: calc(var( --fs-md)*1.2);
    color : var(--color-white);

    max-width: 600px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .educationDescription__container {
        width: 100%;
        padding: 64px 0;
    }
    .educationDescription__column {
        padding: 0 16px;
    }
    .educationDescription__image__container {
        margin-left: 16px;
    }
    .educationDescription__title {
        max-width: 100%;
    }
    .educationDescription__column__text {
        max-width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .educationDescription__container {
        width: 100%;
        padding: 48px 0;
    }
    .educationDescription__column {
        width: 100%;
        padding: 0 16px;
    }
    .educationDescription__image__container {
        display: none;
    }
    .educationDescription__title {
        max-width: 100%;
    }
    .educationDescription__column__text {
        max-width: 80%;
    }
}
