.enhancedItem__container {
    width: 1000px;
    border-top: 2px solid var(--color-primary);
    border-bottom: 2px solid var(--color-primary);

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 40px 0;
}

.enhancedItem__title {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    color: var(--color-white);
    cursor: pointer;
    padding: 0 16px;
}

.enhancedItem__title span {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    color: var(--color-primary);
    cursor: pointer;
    padding: 0 8px 0 0;
}

.enhancedItem__title--disabled {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    color: var(--color-primary);
    padding: 0 32px;
}

.enhancedItem__title:hover {
    transform: translate(0, -4px);
}

.enhancedItem__arrow {
    width: 48px;
    height: auto;
}

.enhancedItem__plus {
    width: 32px;
    height: auto;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .enhancedItem__container {
        width: 80%;
        padding: 32px 0;
    }
    .enhancedItem__arrow {
        width: 40px;
    }
    .enhancedItem__plus {
        width: 24px;
    }
}

@media screen and (max-width: 768px) {
    .enhancedItem__container {
        width: 90%;
        padding: 32px 0;
    }
    .enhancedItem__arrow {
        width: 32px;
    }
    .enhancedItem__plus {
        width: 24px;
    }
    .enhancedItem__title {
        text-align: center;
        font-size: var(--fs-md);
        line-height: var(--fs-md);
    }
    .enhancedItem__title span{
        text-align: center;
        font-size: var(--fs-md);
        line-height: var(--fs-md);
        padding: 0 4px 0 0;
    }
    .enhancedItem__title--disabled {
        text-align: center;
        font-family: 'Abel', sans-serif;
        font-size: var(--fs-md);
        line-height: var(--fs-md);
        color: var(--color-primary);
        padding: 0 16px;
    }
}
