.store__title__container {
    width: 80%;
    height: auto;
    margin: 64px 0 24px;
}

.store__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight: 700;
    color: var(--color-primary)
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .store__title {
        color: var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.store__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    width: 80%;
    height: auto;
    margin: 32px 0;
}

.store__filters__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    height: auto;
    margin: 16px 0;
}

.store__filters__text {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight: 700;
    color: var(--color-white);
    cursor: pointer;
    margin-right: 24px;
    padding: 4px 8px;

    display: flex;
    align-items: center;
}

.store__filters__text span {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-sm);
    line-height: var(--fs-sm);
    font-weight: 700;
    color: var(--color-white);
    cursor: pointer;
    margin-right: 24px;
    padding: 4px 8px;
}

.store__filters__text:nth-child(odd) {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight: 700;
    cursor: pointer;
    margin-right: 24px;
    padding: 4px 8px;
    color: #E84E1B;

    display: flex;
    align-items: center;
}

.store__filters__text:hover {
    transform: translate(0, -4px);
}

.store__filters__text:hover {
    transform: translate(0, -4px);
}

.store__filters__text--active {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight: 700;
    background-color: #FFFFFF;
    cursor: pointer;
    margin-right: 24px;
    padding: 4px 8px;
    border-radius: 12px;
    color: #E84E1B;

    display: flex;
    align-items: center;
}

.store__filters__arrow {
    width: var(--fs-md);
    height: auto;
    align-self: center;
    margin-right: 8px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .store__cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .store__cards {
        grid-template-columns: 1fr;
    }

    .store__title--mobile {
        display: none;
    }

    .store__filters__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 80%;
        height: auto;
        margin: 24px 0 0;
    }

    .store__filters__text {
        line-height: 1.2;
    }
}
