.contact__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : var(--color-white);
    text-align: left;

    margin-bottom: 32px;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .contact__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

