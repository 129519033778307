.landing__banner {
    width: 100%;
    height: auto;
    margin-top: -160px;
}

.landing__testimonial {
    width: 100%;
    height: auto;
    margin: 88px 72px;

    display: flex;
    justify-content: center;

    /*display: grid;*/
    /*grid-template-columns: auto auto;*/
}

.landing__testimonial__image {
    width: auto;
    height: 100%;
    margin: 0;
}

.landing__message {
    width: 100%;
    height: auto;
    margin: 304px 0 88px;
}

.landing__form {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landing__banner {
        margin-top: -88px;
    }
    .landing__testimonial {
        margin: 48px 0 0;
    }
    .landing__message {
        margin: 48px 0 88px;
    }
}

/*This media query was added just to differentiate .landing__message margin*/
/*Everything else remains the same*/
@media screen and (min-width: 501px) and (max-width: 768px) {
    .landing__banner {
        margin-top: -64px;
    }
    .landing__testimonial {
        margin: 64px 0 0;
    }
    .landing__message {
        margin: 176px 0 64px;
    }
    .landing__form {
        margin-bottom: 48px;
    }
}

@media screen and (max-width: 500px) {
    .landing__banner {
        margin-top: -64px;
    }
    .landing__testimonial {
        margin: 64px 0;
        width: 100%;
    }
    .landing__testimonial__image {
         width: auto;
         height: 304px;
         margin-left: 24px;
     }

    .landing__message {
        margin: 88px 0 48px;
    }
    .landing__form {
        margin-bottom: 48px;
    }
}

