.about__videoPlayer {
    width: 80%;
    height: auto;
    margin: 0 0 88px;
}

.about__introduction {
    width: 80%;
    height: auto;
    margin: 0 0 88px;
}

.about__philosophy {
    width: 80%;
    height: auto;
    margin: 0 0 88px;
}

.about__howIWork {
    width: 80%;
    height: auto;
    margin: 0 0 88px;
}

.about__enhancedMenu {
    width: 80%;
    height: auto;
    margin: 88px 0;
}

.about__willLearn {
    width: 80%;
    height: auto;
}

.about__button {
    margin: 64px 0;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .about__videoPlayer {
        width: 80%;
        margin: 0 0 64px;
    }
    .about__introduction {
        width: 80%;
        margin: 0 0 64px;
    }
    .about__philosophy {
        width: 80%;
        margin: 0 0 64px;
    }
    .about__howIWork {
        width: 80%;
        margin: 0 0 64px;
    }
    .about__willLearn {
        width: 80%;
        margin: 0 0 64px;
    }
    .about__enhancedMenu {
        width: 80%;
        margin: 64px 0;
    }
    .about__button {
        margin: 64px 0;
    }
}

@media screen and (max-width: 768px) {
    .about__videoPlayer {
        width: 85%;
        margin: 0 0 48px;
    }
    .about__introduction {
        width: 85%;
        margin: 0 0 48px;
    }
    .about__philosophy {
        width: 85%;
        margin: 0 0 48px;
    }
    .about__howIWork {
        width: 85%;
        margin: 0 0 48px;
    }
    .about__willLearn {
        width: 85%;
        margin: 0 0 8px;
    }
    .about__enhancedMenu {
        width: 85%;
        margin: 48px 0;
    }
    .about__button {
        max-width: 80%;
        margin: 48px 0;
    }
}
