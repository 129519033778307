.aboutPhilosophy__container {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 40% 40%;


    column-gap: 20%;
}


@media screen and (max-width: 768px) {
    .aboutPhilosophy__container {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        row-gap: 32px;
    }
}