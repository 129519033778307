.landing__enhancedMenu__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 120px 0 64px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landing__enhancedMenu__container {
        margin: 32px 0;
    }
}

@media screen and (max-width: 768px) {
    .landing__enhancedMenu__container {
        margin: 16px 0;
    }
}
