.footer__wrapper {
    width: 100%;
    height: auto;
}

.footer__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: auto;
    height: auto;

    padding: 32px 64px;
}

.footer__logo {
    height: var(--logoHeight);
    width: auto;
}

.footer__socialMedia {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer__icon {
    height: 32px;
    width: auto;
    margin: 0 16px;
}

@media screen and (max-width: 768px) {
    .footer__container {
        padding: 24px 32px;
        align-items: center;
    }
    .footer__logo {
        height: var(--logoHeight);
    }
    .footer__icon {
        height: 32px;
        width: auto;
        margin: 0 0 0 16px;
    }
}
