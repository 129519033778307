.button__primary {
    box-shadow: inset 0 32px 0 -12px var(--color-primary);
    background-color: var(--color-primary-3);
    border: 1px solid var(--color-base);
    cursor: pointer;
    color: var(--color-white);
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 8px 24px;
    transition: all ease-in-out 0.2s;
}

.button__primary:hover {
    background-color: var(--color-primary-3);
    box-shadow: none;
    transition: all ease-in-out 0.2s;
}

.button__secondary {
    box-shadow: inset 0 32px 0 -12px var(--color-white);
    background-color: var(--color-grey);
    border: 1px solid var(--color-base);
    cursor: pointer;
    color: var(--color-primary);
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-weight: bold;
    padding: 8px 24px;
    transition: all ease-in-out 0.2s;
}

.button__secondary:hover {
    background-color: var(--color-grey);
    box-shadow: none;
    transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 768px) {
    .button__primary {
        box-shadow: inset 0 32px 0 -12px var(--color-primary);
        font-size: 20px;
        padding: 8px 16px;
    }

    .button__secondary {
        box-shadow: inset 0 32px 0 -12px var(--color-white);
        padding: 8px 16px;
    }
}
