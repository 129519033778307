.educationQuestionItem__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    max-width: 33%;
    margin: 0 32px;
}

.educationQuestionItem__title {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-md);
    line-height : calc(var(--fs-md)*1.25);
    background-color : var(--color-white);
    font-weight: 700;
}

.educationQuestionItem__text {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-sm);
    line-height : calc(var(--fs-sm)*1.25);
    color : var(--color-white);
}
.educationQuestionItem__text span {
    font-family: 'Abel', sans-serif;
    font-weight: 700;
    font-size : var(--fs-sm);
    line-height : calc(var(--fs-sm)*1.25);
    color : var(--color-white);
}

.educationQuestionItem__title--1 {
    width: 200px;
    height: auto;
    margin-bottom: 16px;
}
.educationQuestionItem__title--2 {
    width: 200px;
    height: auto;
    margin-bottom: 16px;
}
.educationQuestionItem__title--3 {
    width: 200px;
    height: auto;
    margin-bottom: 16px;
}
.educationQuestionItem__title--4 {
    width: 152px;
    height: auto;
    margin-bottom: 16px;
}
.educationQuestionItem__title--5 {
    width: 184px;
    height: auto;
    margin-bottom: 16px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .educationQuestionItem__container {
        max-width: 30%;
        margin: 0 16px;
    }
}

@media screen and (max-width: 768px) {
    .educationQuestionItem__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        max-width: 100%;
        margin: 8px 0;
    }
}
