.mainLayout__container {
    width: 100%;
    max-width: var(--maxWidthContainer);
    min-height: 100vh;
    margin: 0 auto;
    /*padding-top: var(--headerHeight);*/
    box-shadow: var(--box-shadow);

    background-color: var(--color-base);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.mainLayout__container main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}