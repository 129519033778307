.landingTestimonial__wrapper {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landingTestimonial__container {
    height: auto;
    width: 70%;
    max-width: 1000px;
    /*margin-right: -30%;*/
    display: flex;
    justify-content: flex-end;
}

.landingTestimonial__text {
    font-family: 'Raleway', sans-serif;
    font-size: var( --fs-md);
    line-height: calc(var( --fs-md)*1.2);
    color : var(--color-white);

    max-width: 80%;
    text-align: right;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landingTestimonial__container {
        max-width: 85%;
    }
}

@media screen and (max-width: 768px) {
    .landingTestimonial__container {
        width: 95%;
    }

    .landingTestimonial__text {
        max-width: 65%;
        align-self: flex-end;
        text-align: right;
    }
}
