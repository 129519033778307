.services__diagnosis__wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.services__diagnosis {
    width: 80%;
    height: auto;
    margin: 88px 0;
}

.services__educationAlternatives {
    width: 80%;
    height: auto;
    margin-bottom: 32px;
}

.services__diagnosis__arrow {
    height: 304px;
    width: auto;
    margin-top: 64px;
    margin-right: 24px;
    align-self: center;
}
.services__diagnosis__arrow--alternatives {
    height: 264px;
    width: auto;
    margin-top: 232px;
    margin-right: 24px;
    align-self: flex-start;
}

.services__diagnosis__image {
    width: auto;
    height: 600px;
    position: absolute;
    top: 0;
    right: 0;
}

.services__alternatives {
    width: 80%;
    height: auto;
    margin: 88px 0;
}


.services__walks {
    width: 100%;
    height: auto;
    margin: 88px 0;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .services__diagnosis {
        width: 80%;
        height: auto;
        margin: 48px 0;
    }
    .services__diagnosis__image {
        width: auto;
        height: 400px;
        opacity: 0.5;
    }

    .services__alternatives {
        width: 80%;
        height: auto;
        margin: 48px 0;
    }

    .services__walks {
        width: 100%;
        height: auto;
        margin: 48px 0;
    }
    .services__diagnosis__arrow--alternatives {
        height: 264px;
        width: auto;
        margin-top: 160px;
        margin-right: 24px;
        align-self: flex-start;
    }
}

@media screen and (max-width: 768px) {
    .services__diagnosis {
        margin: 40px 0;
    }
    .services__diagnosis__image {
        height: 400px;
        opacity: 0.1;
        top: 176px;
    }
    .services__alternatives {
        width: 80%;
        height: auto;
        margin: 40px 0;
    }
    .services__walks {
        width: 100%;
        height: auto;
        margin: 40px 0;
    }
    .services__diagnosis__arrow {
        height: 304px;
        width: auto;
        margin-top: 48px;
        margin-right: 16px;
        align-self: center;
    }
    .services__diagnosis__arrow--alternatives {
        height: 264px;
        width: auto;
        margin-top: 104px;
        margin-right: 16px;
        align-self: flex-start;
    }
}
