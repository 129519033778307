.aboutIntroduction__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
}

.aboutIntroduction__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.aboutIntroduction__arrow {
    height: 100%;
    width: auto;
    max-height: 180px;
    align-self: flex-end;
    margin-right: 24px;
}

.aboutIntroduction__arrow--mobile {
    display: none;
}

.aboutIntroduction__text {
    font-family: 'Raleway', sans-serif;
    font-size : var(--fs-md);
    line-height : var(--fs-md);
    color : var(--color-white);

    margin-right: 104px;
}

.aboutIntroduction__text span {
    color : var(--color-primary);
    font-weight: 700;
}

.aboutIntroduction__subtitle {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-md);
    line-height : calc(var(--fs-md)*1.25);
    color : var(--color-primary);
}

.aboutIntroduction__image {
    margin-top: 88px;
    width: 100%;
    height: auto;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .aboutIntroduction__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 100%;
    }
    .aboutIntroduction__text {
        margin: 48px 0 0 0;
    }
    .aboutIntroduction__arrow {
        max-height: 140px;
    }
    .aboutIntroduction__image {
        margin-top: 64px;
    }
}

@media screen and (max-width: 768px) {
    .aboutIntroduction__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 100%;
    }
    .aboutIntroduction__container {
        flex-direction: column;
    }
    .aboutIntroduction__arrow {
        display: none;
    }
    .aboutIntroduction__arrow--mobile {
        display: block;
        height: 32px;
        width: auto;
        align-self: flex-start;
    }
    .aboutIntroduction__text {
        margin: 16px 0 0 0;
    }
    .aboutIntroduction__image {
        margin-top: 48px;
    }
}
