.packItem__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    margin: 48px 0;
}

.packItem__title__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.packItem__arrow {
    width: 40px;
    height: auto;
    margin-right: 120px;
}

.packItem__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : var(--color-white);
    text-align: right;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .packItem__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.packItem__text {
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-md);
    line-height : var(--fs-md);
    color : var(--color-white);
    text-align: right;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .packItem__container {
        margin: 32px 0;
    }

    .packItem__arrow {
        width: 32px;
        margin-right: 64px;
    }

    .packItem__text {
        max-width: 70%;
    }
}

@media screen and (max-width: 768px) {
    .packItem__container {
        margin: 32px 0;
    }

    .packItem__arrow {
        width: 32px;
        margin-right: 16px;
    }

    .packItem__text {
        max-width: 70%;
    }
}
