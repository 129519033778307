.willLearnItem__container {
    width : auto;
    margin: 16px 0;
    max-width: 60%;
    color: var(--color-primary);
}
.willLearnItem__container:nth-child(2n+1) {
    width : auto;
    margin: 16px 0;
    max-width: 60%;
    color: var(--color-white);
}

.willLearnItem__text {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: 0.4;
    font-weight: 700;
}
.willLearnItem__text--orange {
    color: var(--color-primary);
}

@media screen and (max-width: 768px) {
    .willLearnItem__container {
        margin: 8px 0;
        max-width: 80%;
    }
    .willLearnItem__text {
        font-family: 'Raleway', sans-serif;
        font-size: calc(var(--fs-lg));
        line-height: 0.3;
        font-weight: 700;
    }
}

@media screen and (max-width: 500px) {
    .willLearnItem__text {
        font-size: calc(var(--fs-lg) * 0.75);
    }
}
