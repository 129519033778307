.textInput__wrapper {
    width: 100%;
    height: 100%;
    margin: 8px 0;
}

.textInput__input {
    width: 100%;
    border: 1px solid var(--color-secondary);
    padding: 16px;
    background-color: transparent;

    font-family: 'Abel', sans-serif;
    font-size : var(--fs-sm);
    color: var(--color-white);
}

.textInput__input:focus {
    outline: 2px solid var(--color-secondary);
}