.landingMessage__container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.landingMessage__dog {
    height: 600px;
    width: auto;
}

.landingMessage__message {
    height: auto;
    width: 60%;
    max-width: 904px;

    padding-right: 120px;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landingMessage__dog {
        height: 400px;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px) {
    .landingMessage__container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .landingMessage__dog {
        height: 400px;
        margin-bottom: -248px;
    }
    .landingMessage__message {
        width: 100%;
        padding-right: 0;
        align-self: flex-end;
    }
}

@media screen and (max-width: 500px) {
    .landingMessage__container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .landingMessage__dog {
        height: 200px;
        margin-bottom: -104px;
    }
    .landingMessage__message {
        width: 100%;
        padding-right: 0;
        align-self: flex-end;
    }
}
