.howIWork__container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.howIWork__text {
    font-family: 'Raleway', sans-serif;
    font-size : var(--fs-lg);
    line-height : var(--fs-lg);
    color : var(--color-white);
    font-style: italic;
}
.howIWork__text  span{
    font-family: 'Abel', sans-serif;
    font-size : var(--fs-lg);
    line-height : var(--fs-lg);
    color : var(--color-white);
    font-style: italic;
    font-weight: 700;
}

.howIWork__image {
    width: auto;
    height: 100%;
    margin-right: 64px;  /*Same as .aboutWillLearn__image*/
}

@media screen and (max-width: 500px) {
    .howIWork__image {
        width: auto;
        height: 280px;
        margin-left: 8px;
        margin-right: 8px;
    }
}
