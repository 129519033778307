.walkService__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: auto;

    /*Fix for 100% screen size content*/
    width: auto;
    padding-right: 10%;
}

.walkService__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : var(--color-white);
    max-width: 504px;

    /*Fix for 100% screen size content*/
    padding-left: 10%;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .walkService__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.walkService__description__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 0 96px;
    width: 100%;

    margin-top: 136px;
}

.walkService__description__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.walkService__description__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    font-weight : 700;
    color : var(--color-primary);
    text-align: right;

}

.walkService__description__text {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 500;
    color : var(--color-white);
    text-align: right;

    margin: 72px 0 48px 0;
}

.walkService__description__text span{
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 700;
    color : var(--color-white);
    text-align: right;

    margin: 72px 0 48px 0;
}

.walkService__description__note {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-md);
    line-height: var(--fs-md);
    font-weight : 700;
    color : var(--color-primary);
    text-align: right;

    max-width: 60%;
}

.walkService__packs__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    width: 100%;
    height: auto;

    margin-top: 64px;
}

.walkService__packs__image {
    height: 464px;
    width: auto;
    position: absolute;
    top: 64px;
    left: 0;
}

.walkService__payment {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 64px;
}

.walkService__title--payment {
    text-align: right;
    align-self: flex-end;
    max-width: 800px;
}

.walkService__questions__container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    margin: 88px 0;
    padding-left: 5%;
    max-width: 90%;
}

.walkService__buttons__container {
    width: 100%;
    height: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0;
    gap: 16px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .walkService__container {
        padding-right: 10%;
    }

    .walkService__title {
        max-width: 80%;
        /*Fix for 100% screen size content*/
        padding-left: 10%;
    }

    .walkService__description__container {
        gap: 0 72px;
        margin-top: 64px;
    }
    .walkService__description__text {
        margin: 48px 0;
    }
    .walkService__description__note {
        max-width: 100%;
    }


    .walkService__packs__container {
        margin-top: 16px;
    }
    .walkService__packs__image {
        height: 376px;
        top: 64px;
        left: 0;
    }
    .walkService__payment {
        margin-top: 48px;
    }
    .walkService__questions__container {
        margin: 40px 0;
        padding-left: 10%;
        max-width: 80%;
    }
    .walkService__buttons__container {
         flex-direction: column;
     }
}

@media screen and (max-width: 768px) {
    .walkService__title {
        max-width: 90%;
    }
    .walkService__description__container {
        grid-template-columns: 1fr;
        gap: 32px 0;

        margin-top: 48px;
        /*Fix for 100% screen size content*/
        width: 90%;
        padding-left: 10%;
    }
    .walkService__description__text {
        margin: 24px 0 32px 0;
    }
    .walkService__description__note {
        max-width: 100%;
    }
    .walkService__packs__container {
        margin-top: 24px;
    }
    .walkService__packs__image {
        height: 288px;
        opacity: 0.1;
        top: 120px;
    }
    .walkService__payment {
        margin-top: 40px;
    }
    .walkService__title--payment {
        text-align: right;
        align-self: flex-end;
        max-width: 80%;
    }
    .walkService__questions__container {
        margin: 40px 0;
        flex-direction: column;
        padding-left: 10%;
        max-width: 80%;
    }

    .walkService__buttons__container {
        flex-direction: column;
    }
}
