.landingForm__container {
    width: 100%;
    max-width: 1000px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .landingForm__container {
        max-width: 600px;
        margin-left: -32px; /*Double of textInput padding*/
    }
}

@media screen and (max-width: 768px) {
    .landingForm__container {
        max-width: 70%;
        margin-left: -32px; /*Double of textInput padding*/
    }
}