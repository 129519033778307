.philosophyItem__wrapper {
    width: 100%;
    height: auto;

    display: flex;
    align-items: flex-start;
}

.philosophyItem__container {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.philosophyItem__title {
    width: 100%;
    height: auto;
}

.philosophyItem__text {
    font-family: 'Raleway', sans-serif;
    font-size : var(--fs-md);
    line-height : calc(var(--fs-md) * 1.1);
    background-color : var(--color-white);
    font-weight: 600;
}

.philosophyItem__image {
    height: auto;
    width: 100%;

    margin: 0 32px 16px;
}

.philosophyItem__vision {
    height: 144px;
    width: auto;
    margin-left: 24px;
}

.philosophyItem__mision {
    height: 144px;
    width: auto;
    margin-right: 24px;
}

@media screen and (min-width: 769px) and (max-width: 1000px) {
    .philosophyItem__image {
        height: auto;
        width: 100%;

        margin: 0 24px 16px;
    }
    .philosophyItem__vision {
        height: 104px;
        width: auto;
        margin-left: 24px;
    }

    .philosophyItem__mision {
        height: 104px;
        width: auto;
        margin-right: 24px;
    }
}

@media screen and (max-width: 768px) {
    .philosophyItem__container {
        width: 100%;
        height: auto;
    }
    .philosophyItem__image {
        height: auto;
        width: 100%;

        margin: 0 16px 16px;
    }
}
