.aboutWillLearn__wrapper {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.aboutWillLearn__container {
    width: 80%;
    height: auto;
}

.aboutWillLearn__image {
    width: auto;
    height: 100%;
    margin-right: 64px; /*Same as .howIWork__image*/
}

@media screen and (max-width: 500px) {
    .aboutWillLearn__container {
        width: 90%;
        height: auto;
    }
    .aboutWillLearn__image {
        width: auto;
        height: 280px;
        margin-left: 8px;
        margin-right: 8px;
    }
}
