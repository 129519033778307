.academy__title {
    font-family: 'Raleway', sans-serif;
    font-size: var(--fs-h1);
    line-height: var(--fs-h1);
    font-weight : 700;
    color : var(--color-white);
    text-align: left;

    margin-bottom: 32px;
}

@supports (-webkit-text-stroke: 1px #E84E1B) {
    .academy__title span {
        color : var(--color-base);
        -webkit-text-fill-color: var(--color-base);
        -webkit-text-stroke: 1px #E84E1B;
    }
}

.academy__brochures {
    width: 100%;
    height: auto;
    margin: 24px 0 120px;

    display: flex;
    justify-content: center;
    align-items: flex-start;

    gap: 40px
}

.academy__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 70%;
    margin: 16px 0 24px;
}

.academy__icon {
    width: 48px;
    height: auto;
    margin-right: 16px;
}

.academy__subtitle {
    font-family: 'Abel', sans-serif;
    font-size: var(--fs-lg);
    line-height: var(--fs-lg);
    font-weight : 700;
    color : #E84E1B;
}


@media screen and (min-width: 769px) and (max-width: 1000px) {
    .academy__brochures {
        width: 100%;
        height: auto;
        margin: 24px 0 88px;
        gap: 16px;
    }
}

@media screen and (max-width: 768px) {
    .academy__brochures {
        width: 100%;
        height: auto;
        margin: 24px 0 48px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
    }
    .academy__row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
        margin: 8px 0 0;
    }
}
